import Vue from 'vue'
import VueRouter, { RouterLink } from 'vue-router'
import Home from "../views/main/main.vue";


// 获取原型对象push函数
const originalPush = VueRouter.prototype.push
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace
// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}


Vue.use(VueRouter)
// "vue-router": "^3.5.1",
const routes = [
 
  {
    path:'/warehouse',
    component:()=>import('../views/warehouse/warehouse.vue')
  },
  {
    path: '/',
    // redirect:'/singlelamp ',
    component:()=>import('../views/main/main.vue'),
    children:[
      {
        path:'datavisual',
        meta:{
          title:'数据展示'
        },
        component:()=>import('../views/datavisual/datavisual.vue')
      },
      {
        path:'airswitch',
        meta:{
          title:'空开网关'
        },
        component:()=>import('../views/airswitch/airswitch.vue')
      },
      {
        path:'eightcont',
        meta:{
          title:'八路集控器'
        },
        component:()=>import('../views/eightcont/eightcont.vue')
      },
      {
        path:'map',
        meta:{
          title:'地图'
        },
        component:()=>import('../views/map/map.vue')
      },
      {
        path:'controller',
        meta:{
          title:'集控器数据'
        },
        component:()=>import('../views/controller/controller.vue')

      },
      {
        path:'controlmap',
        meta:{
          title:'集控器地图'
        },
        component:()=>import('../views/controlmap/controlmap.vue')

      },
      {
        path:'control',
        meta:{
          title:'策略控制'
        },
        component:()=>import('../views/control/control.vue')

      },
      // {
      //   path:'lamptests',
      //   meta:{
      //     title:'单灯数据测试'
      //   },
      //   component:()=>import('../views/lamptests/lamptests.vue')
      // },
      {
        path:'singlelamp',
        meta:{
          title:'单灯数据'
        },
        component:()=>import('../views/singlelamp/singlelamp.vue')

      },
      {
        path:'historydata',
        meta:{
          title:'单灯历史数据'
        },
        component:()=>import('../views/historydata/historydata.vue')

      },
      {
        path:'strategy',
        meta:{
          title:'策略设置'
        },
        component:()=>import('../views/strategy/strategy.vue')

      },
      {
        path:'operatLogs',
        meta:{
          title:'操作日志'
        },
        component:()=>import('../views/operatLogs/operatLogs.vue')

      },
      {
        path:'devrecord',
        meta:{
          title:'设备日志'
        },
        component:()=>import('../views/devrecord/devrecord.vue')

      },
      {
        path:'IoTnetworkcard',
        meta:{
          title:'物联网卡'
        },
        component:()=>import('../views/IoTnetworkcard/IoTnetworkcard.vue')

      },
      {
        path:'warning',
        meta:{
          title:'报警信息'
        },
        component:()=>import('../views/warning/warning.vue')

      },
    ]
  },
  {
    path: '/system',
    // redirect:'noredirect',
    component:Home,
    meta: {
      title: '系统管理',
    },
    children:[
      {
        path:'productmanage',
        meta:{
          title:'产品管理'
        },
        component:()=>import('../views/productmanage/productmanage.vue')

      },
      {
        path:'usermanage',
        meta:{
          title:'用户管理'
        },
        component:()=>import('../views/usermanage/usermanage.vue')

      },
      {
        path:'rolemanage',
        meta:{
          title:'角色管理'
        },
        component:()=>import('../views/rolemanage/rolemanage.vue')

      },
      {
        path:'menumanage',
        meta:{
          title:'菜单管理'
        },
        component:()=>import('../views/menumanage/menumanage.vue')

      },
    ]
  },
  // {
  //   path:'/login',
  //   component:()=>import('../views/login/login.vue')

  // },
  {
    path:'/shows',
    component:()=>import('../views/shows/shows.vue')
  }, 
]

const router = new VueRouter({
  // history模式，需要和服务端配合才能在生产环境下正常使用
  mode: 'history',
  routes
})
// 全局前置守卫
router.beforeEach((to,from,next)=>{

  if(to.path == '/shows'){  //访问登录页
    next();
    return;
  }

  // if(to.path == '/warehouse'){ //访问仓库页
  //   next();
  //   return;
  
  // }
  // 获取登录用户信息
  let userobj = localStorage['userInfo']?JSON.parse(localStorage['userInfo']):'';
  let userPathArr =sessionStorage['usermenu']? JSON.parse(sessionStorage['usermenu']):[];
  // 判断用户是否登录
  if(userobj==''){
    next('/shows')
    return;
  }
  else{
    let allPathArr = ['/', '/shows','/map', ...userPathArr];
    if (allPathArr.indexOf(to.path) == -1) {
      next('/shows'); //没有权限,去登陆
      return;
    } else {
      next(); //有权限,放行
      return;
    }
  
  }
// 用户登录
  // let userPathArr =JSON.parse(sessionStorage['usermenu']);

  
 
})
// 全局前置路由
// 挂载路由导航守卫
// to 将要访问的路径
// from 从哪个路径跳转而来
// next 是个函数，表示放行 next() 放行  next('/login') 强制跳转

export default router

import {sendDelete, sendGet,sendPost} from './request.js'

// 根据id得token
export let gettoken = d=> sendGet('/user/getTokenByUserId',d)
// 展示所有项目
export let showalldev = d=> sendPost('/project/getProjectList',d)
// 管理员登录
export let userlogin = d=> sendPost('/user/login',d)
// 获取所有权限列表
export let getAllPermissions = ()=> sendGet('/permission/getAllPermissions')
// 报警信息
export let getWarning = d=> sendPost('/deviceExceptionLog/getDeviceExceptionLogPage',d)


// 集控器子节点状态
export let getStatesList = d=>sendGet('/centralized/getCentralizedChildStatesList',d)

// 子节点控制
export let setControlsons = d=>sendPost('/centralized/centralizedChildControl',d)
// 集控器控制
export let setControl = d=>sendPost('/centralized/centralizedControl',d)
// 子节点数据
export let getcontlists = d=> sendPost('/centralized/getCentralizedChildList',d)
// 集控器数据
export let getcontroldata = d=> sendPost('/centralized/getCentralizedPage',d)

// ========================产品管理========================================
// 产品类别
export let ProductCategory =d=>sendGet('/product/getProductTypeList',d)
// 删除产品
export let deleteProduct =d=>sendDelete('/product/deleteProduct',d)
// 根据ID获取
export let getoneProduct =d=>sendGet('/product/getProductById',d)
// 修改产品
export let editProduct =d=>sendPost('/product/updateProduct',d)
// 添加产品
export let addProduct =d=>sendPost('/product/insertProduct',d)
// 获取所有产品列表
export let getallProduct =()=> sendGet('/product/getAllProductList')

// ========================产品管理结束========================================

// ========================用户管理========================================
// 用户操作日志
export let userLogs = d=> sendPost('/user/getUserOperationLogs',d)
// 用户路由接口
export let userRouter = d=> sendGet('/user/getPermissions',d)
// 删除用户
export let deleteUser =d=>sendDelete('/user/deleteUserById',d)
// 根据ID获取
export let getoneUser =d=>sendGet('/user/getUserById',d)
// 修改用户
export let editUser =d=>sendPost('/user/updateUserById',d)
// 添加用户
export let addUser =d=>sendPost('/user/insertUser',d)
// 获取所有用户列表
export let getallUser =()=> sendGet('/user/getUserList')

// ========================用户管理结束========================================

// ========================角色管理开始=======================================
// 删除角色
export let deleterole =d=>sendDelete('/role/deleteRole',d)
// 根据ID获取
export let getonerole =d=>sendGet('/role/getRoleById',d)
// 修改角色
export let editrole =d=>sendPost('/role/updateRole',d)
// 添加角色
export let addrole =d=>sendPost('/role/insertRole',d)
// 获取所有角色列表
export let getallrole =()=> sendPost('/role/getRoleList')
//=========================角色管理结束===========================================

// ===============================权限管理========================================
// 删除菜单
export let deletemenu =d=>sendDelete('/permission/deletePermission',d)
// 根据ID获取权限
export let getonemenu =d=>sendGet('/permission/getPermissionById',d)
// 修改菜单
export let editmenu =d=>sendPost('/permission/updatePermission',d)
// 添加菜单
export let addmenu =d=>sendPost('/permission/insertPermission',d)
// 用户认证
export let checkauth =d=>sendGet('/user/auth',d)
// 获取所有权限列表
export let getPermissions =()=> sendGet('/permission/getAllPermissions')
// 新增
export let addPermissions =d=> sendPost('/permission/insertPermission',d)

// =============================权限管理结束======================================


// ===============================地图相关=======================================
// 在线离线数据统计
export let getMapdevs =()=> sendGet('/device/getDeviceOnlineInfo')
// 统计所有数据
export let getMapStatistics =()=> sendGet('/centralized/getMapStatistics')
// 地图
export let getMaps =()=> sendGet('device/getAllDevicesLocationList')
// 地图数据接口
export let Mapdatas =()=> sendGet('/device/getDevicesLocationList')
// 集控器地图
export let getMapdatas =()=> sendGet('/centralized/getCentralizedLocationList')

// ===============================物联网卡=======================================
// 物联网卡页面
export let getCardList = d => sendPost('/deviceCard/getDeviceCardPage',d)
// 更新物联网数据
export let updateCardList = () => sendPost('/deviceCard/updateAll')
// ===============================物联网卡结束=======================================


// ==============================单灯数据页面开始==================================
// 控制所有设备
export let controlAllDev = d => sendPost('/device/allDeviceOperate',d)
// 控制所有调光等级
// export let allLightlev = d => sendPost('/device/setDimmingLevel',d)
// 获取在线和不在线设备数量
export let getDevicenums = d => sendGet('/device/getDeviceOnlineInfo',d)
// 执行策略
export let setDevcontrol = d => sendPost('/device/executionPolicy',d)
// 获取产品列表
export let getProductList = d => sendGet('/product/getProductList',d)
// 控制所有的开关
// export let ControlLamp = d => sendGet('/device/setSwitchState',d)
// 设备详情数据接口
export let Devicelist = d => sendPost('/device/getDevicePage',d)
// 单灯控制接口
export let Devicecontrol = d => sendPost('/device/deviceOperate',d)
// =================================单灯页面结束==================================

// ==============================设备日志页面=====================================
// 查询指定日期设备上线数量
export let DevrecordNums = d => sendPost('/deviceLog/getOnlineQuantityByTime',d)
// 获取设备日志信息
export let DevrecordInfo = d => sendPost('/deviceLog/getDeviceLogPage',d)
// ===========================设备日志页面结束====================================

// ===================策略设置页面================================================
// 策略数据列表
export let Strategylist =()=> sendGet('/policy/getPolicyList')
// 新建策略组
export let setStrategy = d=> sendPost('/policy/addPolicyGroup',d)
// 获取策略组列表
export let getStrategy = ()=> sendGet('/policy/getPolicyGroupList')
// 集控器策略
export let controldata =d=> sendPost('/devicecontroldata',d)
// ===================策略设置页面结束================================================


// ==================单灯历史数据==============================================
// 单灯历史数据查询
export let Devicehistory =d=> sendGet('/deviceLog/getDeviceLogStatistics',d)
// ==================单灯历史数据结束==============================================

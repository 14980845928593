import { render, staticRenderFns } from "./Savecode.vue?vue&type=template&id=5b952365&scoped=true"
import script from "./Savecode.vue?vue&type=script&lang=js"
export * from "./Savecode.vue?vue&type=script&lang=js"
import style0 from "./Savecode.vue?vue&type=style&index=0&id=5b952365&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b952365",
  null
  
)

export default component.exports
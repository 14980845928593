<template>
  <div class="main">
    <el-container style="height:100%">
      <el-header>
        <ul class="headerlist">
          <!-- 系统名 -->
          <li>
            <img src="../../assets/image/title.png" />
          </li>
          <li>
            <img src="../../assets/image/lpd.jpg" />
          </li>
          <li>
            <el-input v-model="input" placeholder="请输入查询内容">
              <el-button @click="searchdata" slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </li>
          <li v-if="islogin">
            <img @click="logininfo" src="../../assets/image/user.png" />
          </li>
          <li>
            <div class="grid-content">{{ username }}</div>
          </li>
        </ul>
        <div v-if="isshow" class="getout" @click="logout">返回首页</div>
      </el-header>

      <el-container>
        <el-aside width="200px">
          <el-row class="tac">
            <!-- 导航菜单 -->
            <el-col>
              <el-menu
                :router="true"
                :unique-opened="true"
                default-active="2"
                class="el-menu-vertical-demo"
                background-color="#e2e7ed"
                active-text-color="#0099FF"
              >
                <!-- 菜单一 -->
                <!-- <el-submenu index="1" v-if="this.controlArr.length>0">
                  <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>地图信息</span>
                  </template>
                  <el-menu-item index="/controlmap">集控器地图</el-menu-item>
                </el-submenu> -->
                <!-- 菜单二 -->
                <el-submenu index="2"  v-if="this.routerArr.length>0">
                  <template slot="title">
                    <i class="el-icon-menu"></i>
                    <span>智能照明</span>
                  </template>
                  <el-menu-item
                    v-for="(item,index) in routerArr"
                    :key="index"
                    :index="item.path"
                  >{{ item.permissionName}}</el-menu-item>
                </el-submenu>
                <!-- 菜单三 -->
                <el-submenu index="3" v-if="this.permArr.length>0">
                  <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span>系统管理</span>
                  </template>
                  <el-menu-item  v-for="(item,index) in permArr"
                    :key="index"
                    :index="item.path">{{ item.permissionName}}</el-menu-item>
                </el-submenu>
                <!-- 菜单四 -->
                <el-menu-item v-if="this.warnArr.length>0" index="/warning">
                  <i class="el-icon-document"></i>
                  <span slot="title">报警信息</span>
                </el-menu-item>
              </el-menu>
            </el-col>
          </el-row>
        </el-aside>
        <!-- 内容部分 -->
        <el-main>
          <!-- 公告栏 -->
          <!-- <marquee
            onmouseover="this.stop()"
            onmouseout="this.start()"
            direction="left"
            bgcolor="#ffcc66"
            style="color:#333;font-size:12px;line-height:24px;margin-top:-10px"
          >
            <i class="el-icon-message-solid"></i>
            <span>尊敬的用户你好！欢迎您登录使用！祝您生活愉快！使用过程中如有问题请联系浙江雷培德科技有限公司，电话：0571-87269217</span>
          </marquee> -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { userRouter} from "../../request/api";
import { successmsg, failmsg } from "../../tools/tools";

export default {
  data() {
    return {
      warnArr:[],
      controlArr:[],
      permArr:[],
      routerArr: [],
      // 用户名
      username: "",
      // 用户是否登录
      islogin: true,
      // 登录退出菜单的隐藏
      isshow: false,
      // 搜索框的内容
      input: ""
    };
  },
  created() {
  },
  mounted() {
    // 获取localStorage中的登录信息
    let userInfo = localStorage["userInfo"]
      ? JSON.parse(localStorage["userInfo"])
      : false;
    if (!userInfo) {
      this.islogin = false;
    } else {
      this.username = userInfo.username;
    }
     // 路由
     this.getuserRouter();
  },
  methods: {
    // 获取路由信息
    getuserRouter() {
      userRouter()
        .then(d => {
          if (d.code == "0000") {
            // 菜单 用户按钮 nodeType  1 菜单 0 目录 2 按钮
            const uarr = d.data.filter(item => item.nodeType == 1);
            this.warnArr = uarr.filter(item =>item.path =='/warning')
            this.controlArr = uarr.filter(item =>item.path =='/controlmap')
            this.permArr = uarr.filter(item =>(item.path).length >17)
            this.routerArr = uarr.filter(item =>(item.path).length <17 && item.path !== '/controlmap' && item.path !== '/warning' );
          }
        })
        .catch(e => {
          console.log("路由接口调用失败");
        });
    },
    // 搜索查询
    searchdata() {
      console.log("查询成功！");
    },
    // 点击退出登录
    logout() {
      this.$router.replace('/shows')
      // 清除sessionstorge中的数据
      localStorage.removeItem("userInfo");
      sessionStorage.removeItem("ButtonPermissions");
      sessionStorage.removeItem("usermenu");
      
      // this.$router.push("/showdevs");
    },
    // 点击头像显示退出登录
    logininfo() {
      this.isshow = !this.isshow;
    }
  }
};
</script>

<style scoped>
/* 头部ul */
.headerlist {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
}
li i {
  color: #fff;
}
/* 退出登录 */
.getout {
  text-align: center;
  position: fixed;
  right: 10px;
  z-index: 2;
  font-size: 14px;
  width: 100px;
  height: 50px;
  line-height: 50px;
  background: #f4f1f1;
}
.getout:hover {
  cursor: pointer;
}
/* 系统名称 */
.headerlist li:nth-child(1) {
  color: #fff;
  width: 180px;
}
.headerlist li:nth-child(1) img {
  color: #fff;
  width: 180px;
}
/* 图标框 */
/* .headerlist li:nth-child(2),
li:nth-child(3),
li:nth-child(4),
li:nth-child(5) {
  width: 50px;
} */
/* 图标 */
.headerlist li:nth-child(2) {
  width: 6vw;
}
.headerlist li:nth-child(2) img {
  width: 60px;
}

/* 搜索框 */
.headerlist li:nth-child(3) {
  width: 80vw;
}
/* 头像 */
.headerlist li:nth-child(4) {
  width: 40px;
  height: 60px;
  margin-left: 10px;
}
li img {
  vertical-align: middle;
  width: 40px;
  border-radius: 50%;
  line-height: 60px;
}
/* 用户名 */
.headerlist li:nth-child(5) {
  width: 60px;
  margin-left: 10px;
}
/* 移入变手 */
.menu:hover {
  cursor: pointer;
}
/* 退出登录 */
.menu {
  width: 120px;
  height: 40px;
  background: #f4f1f1;
  color: #000;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 50px;
  z-index: 1;
  border-radius: 4px;
}
.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.el-header {
  font-size: large;
  background-color: skyblue;
  color: #333;
  line-height: 60px;
}
.el-aside i {
  color: #333;
}
.el-aside {
  background-color: #e2e7ed;
  color: #333;
  line-height: 200px;
}

.el-main {
  /* background-color: #E9EEF3; */
  color: #333;
  /* text-align: center; */
  /* line-height: 160px; */
}

body > .el-container {
  margin-bottom: 40px;
}
</style>

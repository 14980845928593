import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import router from '@/router';
import{successmsg,failmsg}from '../tools/tools'

axios.defaults.headers.post['Content-Type']='application/json';

// 请求拦截器：对所有请求数据作统一处理
axios.interceptors.request.use(config=>{
	// console.log(`当前请求地址为：${config.url}   当前请求方式为：${config.method}`);
    //获取localStorage中的Token并将Token通过请求头信息发送到服务端：
	let tokens = localStorage['userInfo']?JSON.parse(localStorage['userInfo']).token:'';
	//将Token通过请求头信息发送到服务端：
	config.headers.Authorization = tokens;

	return config;
});

//开发环境 8080
if (process.env.NODE_ENV==="development") {
    Vue.prototype.imgHost = "http://159.138.44.42:8000"
}

//生产环境：将打包后的vue项目代码部署到服务端应用程序中
if (process.env.NODE_ENV==="production") {
    Vue.prototype.imgHost = ""
}


//响应拦截器：对所有响应数据作统一处理
axios.interceptors.response.use(d=>{
    // console.log(d,'6666');
    if(d.data.code !=="0000"){
        failmsg(d.data.message)
       console.log(`服务端返回的异常信息为：${d.data.message}`);
        // if(d.data.code == '0002'){
        //     localStorage.removeItem('userInfo');
        //     router.push('/showdevs')
        //     window.localStorage.setItem('tokenFail',true)
           
        // }
    }
     return d.data;
 });
 
 // 封装方法delete
export function sendDelete(url,params={},headers={}){
    // url请求地址  params请求时传参  header请求头传参
    return axios.delete(url,{params,headers})

}
// 封装方法:通过axios.get方法发送get请求与服务端交互
export function sendGet(url,params={},headers={}){
    // url请求地址  params请求时传参  header请求头传参
    return axios.get(url,{params,headers})

}
// 封装方法：通过axios.post方法发送post请求与服务端交互
export function sendPost(url,params={},headers={}){
    // 将参数转换成查询字符串格式
    // let data = ''
    // data = qs.stringify(params)
    // let data = qs.stringify(params)
    return axios.post(url,params,{headers})

}